@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

/* RATE */
.starsRow{ 
    display: flex;
    flex-direction: row;
    text-align: center;
    cursor: pointer;
}
.star {
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    background: linear-gradient(to bottom, rgb(255 211 227) 0%, rgb(249 238 214) 100%);
    position: relative;

  }

  .star:before {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    background: linear-gradient(to bottom, rgb(255 211 227) 0%, rgb(249 238 214) 100%);
    z-index: 1;
   
  }
  
  .star,
  .star:before {
    transition: ease-in-out 1000ms;
    -webkit-clip-path: polygon(50% 0%, 66% 27%, 98% 35%, 76% 57%, 79% 91%, 50% 78%, 21% 91%, 24% 57%, 2% 35%, 32% 27%);
    clip-path: polygon(50% 0%, 66% 27%, 98% 35%, 76% 57%, 79% 91%, 50% 78%, 21% 91%, 24% 57%, 2% 35%, 32% 27%);
  }
  
  .star:hover {
    background: linear-gradient(to bottom, rgb(255 211 227) 0%, rgb(249 238 214) 100%);
    transform: rotate(360deg);
  }
  
  .star:hover:before {
    background: linear-gradient(to bottom, rgb(255 211 227) 0%, rgb(249 238 214) 100%);
    transform: rotate(360deg);
  }

  .emptyStar{
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    background: linear-gradient(to bottom, rgba(197, 196, 196, 1) 0%, rgba(180, 179, 178, 1) 100%);
    position: relative;
  }
  .emptyStar:before {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    background: linear-gradient(to bottom, rgba(221, 220, 219, 1) 0%, rgba(209, 208, 206, 1) 100%);
    z-index: 1;
  }
  
  .emptyStar,
  .emptyStar:before {
    -webkit-clip-path: polygon(50% 0%, 66% 27%, 98% 35%, 76% 57%, 79% 91%, 50% 78%, 21% 91%, 24% 57%, 2% 35%, 32% 27%);
    clip-path: polygon(50% 0%, 66% 27%, 98% 35%, 76% 57%, 79% 91%, 50% 78%, 21% 91%, 24% 57%, 2% 35%, 32% 27%);
  }

.wrapper{
    display: flex;
    flex-direction: row;
    background: #f5e7e4;
    border-radius: 10px;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
}
.myslidePhoto{
object-fit: cover;
display: flex;
height: 100px;
width: 100px;
vertical-align: middle;
background-size: cover;
background-repeat: no-repeat;
border-radius: 100px;
border: 0.5px solid #f5e7e4;
box-shadow: 0 0 20px 0.5px #7674748f;
align-items: center;
justify-content: center;
}
.myslideName{
    font-size: 32px;
  color: #223341;
  font-family: 'Lobster', cursive;
  padding: 0;
  margin: 0;
}

/* Review */

.reviewWrapper{
    display: flex;
    flex-direction: column;
    align-items: center
}
.reviewWrapper>h2{
    font-size: 14px;
    color: gray;
    text-align: left;
}
.reviewWrapper>p{
    text-align: left;
}
@media (max-width:768px) {
  .wrapper{
    flex-direction: column;
  }
  .reviewWrapper>p{
    font-size: 12px;
  }
  .myslideName{
    font-size: 18px;
  }
  .myslidePhoto{
    height: 80px;
    width: 80px;
    margin-top: 5px;
  }
}