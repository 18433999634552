  .mySwiper2 {
    width: 300px;
    height: 150px;
  }
  
  .swiper-slide{
  }
  .swiper-slide img {
    border-radius: 10px;
    max-height: 300px;
  }
  .mySwiper2.swiper-slide{
    height: auto;
     width: 300px;
     height: 100px;
}

.swiper{
    width: 100%;
    height: 100%;
}
.mySwiper2.swiper-slide img{
border-radius: 10px;
object-fit: cover;
}
.this-slide{
  display: flex;

  max-height: 330px;

  align-items: center;
  justify-content: center;
}
.swiper-button-prev{
  color:#009cb3
}
.swiper-button-next{
  color:#009cb3
}