@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15vh;
}
.QuestionsDiv{
    display: flex;
    flex-direction: column;
    box-shadow: 0 3px 7px -1px rgb(0 0 0 / 10%);
    background: white;
    border-radius: 10px;
    width: 90%;
    margin: 10px;
    z-index: 10;
}
.QuestionsDivTop{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 5vh;
    color: #213341;
}
.QuestionsDivBottom{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
}
.QuestionsDivBottomLeft{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    border-radius: 20px;
    margin: 10px 10px 0 10px;
    position: relative;

}
.QuestionsDivBottomRight{
    flex-direction: column;
    width: 40%;
    overflow-y: scroll;
    max-height: 400px;
    margin: 10px;
    box-shadow: inset 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
.QuestionListElement{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    color: #213341;
    min-height: 50px;
    height: fit-content;
    text-align: left;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    background-color: #f5e7e4;
    border-radius: 10px;
    margin: 10px;
    transition: ease-in-out 200ms;
}
.QuestionListElement:hover{
   
    scale: 1.01;
    background-color: #f5e7e4;

}
.QuestionListElement:active{
 
    background: #c09c94;
   
}
.QuestionListElementActive{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    color: #213341;
    min-height: 50px;
    height: fit-content;
    text-align: left;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 10px;
    transition: ease-in-out 200ms;
    scale: 1.01;
    background: #d2bebf;
}
.headerAndSeparator{
    color:#213341;
    align-self: start;
    margin-left: 5%;
  }

.filledCheckbox{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    border: 0.5px solid rgb(17 17 33 / 10%);
    box-shadow: inset 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.1);
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 50%;
 background: #ffffffa2
}
.chosen{
position: absolute;
font-size: 24px;
opacity: 1;
color: #213341;
transition: ease-in-out 50ms;
}
.nonChosen{
    position: absolute;
    font-size: small;
    opacity: 0;
    color: #b0b6bc;
    transition: ease-in-out 50ms;
}

.meSvg{
    position: absolute;
    bottom: 0;
    width: 40%;
    z-index: 10;
}

.answerPolygon{
    box-shadow: 0 0 7px -1px rgba(255, 255, 255, 0.309);
    height: 40%;
    text-align:left;
    display: flex;
    position: absolute; 
    left: 35%;
     bottom: 35%;
     
    box-shadow: 0 3px 50px 5px rgb(0 0 0 / 10%);
    background: #ffffff;
    border-radius: 10px;
    z-index: 10;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    box-sizing: border-box;
    justify-content: center;
    font-size: 16px;
    color: #223341;
  
}
@media (max-width:768px) {

    .headerAndSeparator{
        align-self: center;
        margin-left: 0;
        width: 95%;
    }
    .QuestionsDivBottom{
        flex-direction: column
    }
    .QuestionsDivBottomRight{
        width: auto;
        max-height: 300px;
    }
    .QuestionsDivBottomLeft{
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        width: 100%;
        border-radius: 20px;
        margin: 10px 10px 0 10px;
        position: relative;
        align-items: center
    }
    
    .meSvg{
        position: unset;
        width: 30%;
    }
    .answerPolygon{
        position: unset;
        height: auto;
        overflow-y: scroll;
        overflow-anchor: auto;
        overflow-block: scroll;
        height: 90%;
        min-height: 180px;
        max-height: 180px;
        display: block;
        display: flex;
        padding-top: 5px;
        padding-bottom: 5px;
        width: 65%;
        justify-content: baseline;
    }
    .wrapper{
        margin-top: 10vh;
    }
  }
