@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
.UnderHeader{
  position: relative;
  display: flex;
  flex-direction: column;
    
  justify-content: space-between;
  align-items: center;
    background: #ffffff;
    background-position: 15% 85%;
    background-image: linear-gradient(0deg, rgba(245,231,228,0) 0%, rgba(245,232,229,0) 2%, rgba(255,255,255,0) 43%, rgba(34,51,65,0.4990589985994398) 96%, rgba(34,51,65,0.779171043417367) 100%), url('./svgbg.svg');
    background-image: -webkit-linear-gradient(to bottom 0deg, rgba(245,231,228,0) 0%, rgba(245,232,229,0) 2%, rgba(255,255,255,0) 53%, rgba(34,51,65,0.4990589985994398) 85%, rgba(34,51,65,0.779171043417367) 100%), url('./svgbg.svg');
  background-repeat: no-repeat, no-repeat, no-repeat;

  background-size: cover;

  height: 108vh;
color: white;
z-index: 1;
transition: 2s opacity;
backdrop-filter: blur(5px);

-moz-opacity: 1;
-khtml-opacity: 1;
opacity: 1;
-ms-filter:"progid:DXImageTransform.Microsoft.Alpha"(Opacity=100);
filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
filter:alpha(opacity=100);
transition: opacity .30s ease-in-out;
-moz-transition: opacity .30s ease-in-out;
-webkit-transition: opacity .30s ease-in-out;
}

.dh1{
    display: flex;
    width: 50%;
    font-size: 48px;
    z-index: 1;
    transition: 3s opacity;
    text-align: center;
    justify-content: space-around;
}
.dh2{
    display: flex;
    width: 50%;
    font-size: 36px;
    z-index: 1;
    transition: 3s opacity;
    text-align: center;
    justify-content: space-around;
}
.dh3{
  display: flex;
  width: 100%;
  font-size: 48px;
  font-weight: 700;
  z-index: 1;
  color: #223341;
  transition: 3s opacity;
  text-align: left;
  margin-bottom: 0;
  justify-content: space-around;
}
.pOnline{
  font-size: 32px;
  color: #223341;
  font-family: 'Lobster', cursive;
  padding: 0;
  margin: 0;
}
.blur{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(4, 4, 4, 0.2); 
  backdrop-filter: blur(3px); 
  height: 100%;
	width: 100%;
  z-index: 3;
  transition: 2s opacity;
}
.invisible{
  display: none;
}

.h1h2{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10%;
    align-items: stretch;
}
.coverBlock{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 30%;
  display: flex;
  width: 100%;
  /* right: 5%; */
  cursor: pointer;
  gap: 5px;
  padding: 10px 20px;
  text-decoration: none;
  transition: background-color 400ms, border-color 400ms;
  border-radius: 50px;
}

.line{
  height: 20px;
  background-color: aqua;
}
.coverBtnDiv{
    display: flex;
    width: 50%;
    justify-content: space-around;
}
.coverDivBtn{
 display: none;
}
.coverBtn {
    align-items: center;
    backdrop-filter: blur(3px);
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    bottom: 10px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;  
    display: inline-flex;
    gap: 5px;
    left: 10px;
    padding: 10px 20px;
    text-decoration: none;
    transition: background-color 400ms, border-color 400ms;
    z-index: 10;
  }
  
  .coverBtn:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
/* телефон iphone 12pro */
@media (max-width: 768px) {
  .UnderHeader{
    height: 100vh;
  }
  .coverBlock{
    width: 100%;
    right: 0;
    box-sizing: border-box;
  }
  .dh3{
    text-align: center;
    font-size: 32px;
  }
  .coverDivBtn{
    display: none;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    width: 10vw;
    background-color: #f5e7e4;
    box-shadow: 0 3px 7px -1px rgb(0 0 0 / 10%);
    opacity: 0.9;
  }
  .h1h2{
    display: flex;
    height: 100%;
    margin-top: 35%;
    margin-bottom: 10%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .coverBlock{
    /* position: unset; */
    position: absolute;
    top: 40%;
  }
}

@media (max-width:1050px) and (min-width:800px) {
  .h1h2{
    align-items: center;
  }
  .coverBlock{
    width: unset; 
     right: unset; 
  }
  
}
