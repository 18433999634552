*{

} 
.HeaderMain{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.HeaderAnotherPage{
      width: 100%;
      height: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #d0b5af;
      color: aliceblue;
      justify-content: space-between;
}
.HeaderMenu{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50%;
    border-radius: 10px;
    margin: 10px;
}
.UpperHeaderPart{
    display: flex;
    width: 40%;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

}

.navlogo{
    display: flex;
    width: 70%;
    min-width: 150px;
}

.acclogo{
    display: flex;
    width: 10%;
    margin-right: 10px;
    transition: ease-in-out 200ms;
}
.acclogo:hover{
    scale: 1.1;
}
.PersAccDiv{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    justify-content: center;
}
.PersAccDiv p{
    padding: 3px;
    color: #ea6020
}
.MenuElement{
    display: flex;
    font-size: 20px;
    text-decoration: none;
    align-items: center;
    color: rgb(255 255 255);
    border-radius: 15px;
    transition: ease-in-out 200ms;


    align-items: center;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: rgba(248, 225, 225, 0.193);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    bottom: 10px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: inline-flex;
    gap: 5px;
    left: 10px;
    text-decoration: none;
    color: #223341;
    padding: 10px 20px;
    margin: 15px 0 0 0;
    text-decoration: none;
    transition: background-color 400ms, border-color 400ms;
}

.MenuElementText{
    text-decoration: none;
}
.MenuElement:hover{
    display: flex;
    align-items: center;
    color: #213341;
    transition: ease-in-out 200ms;
    
}

.active{
    color: #213341;
    text-decoration: none;
}
.active:hover{
    display: flex;
    align-items: center;
    color: #213341;
    transition: ease-in-out 200ms;
}
.active:active{
    color:#049cb3
}
.noactive{
    text-decoration: none;
    color: rgb(255 255 255);
}
.noactive:hover{
    display: flex;
    align-items: center;
    color: #213341;
    transition: ease-in-out 200ms;  
}
.noactive:active{
    color:#049cb3
}
.HeaderMenu_main{
    display: flex;
    width: 100%;
    gap: 20px;
    padding-right: 50px;
    padding-left: 50px;
    justify-content: flex-end;

}
@media (min-width: 1200px) {
    .navlogo{
       width: 40%;
    }
}
/* телефон iphone 12pro */
@media (max-width: 768px) {
   
    .HeaderMenu{
        display: flex;
        gap: 20px;
        box-sizing: border-box;
        padding: unset;
        background-color: none;
        justify-content: flex-end;
        align-items: center;
        border-radius: 0px;
        flex-direction: row;
    }
    .HeaderMenu_main{
        display: flex;
        width: 90%;
        box-sizing: border-box;
        padding-right: 20px;
        padding-left: 20px;
        justify-content: space-around;
    }
    .navlogo{
        width: 40px;
    } 
    .HeaderMain{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    .acclogo{
        width: 15%;
    }
    .HeaderAnotherPage{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    .HeaderAnotherPage>.HeaderMenu{
        display: flex;
        gap: 20px;
        box-sizing: border-box;
        padding: unset;
        background-color: none;
        justify-content: flex-end;
        align-items: center;
        border-radius: 0px;
        flex-direction: row;
    }
}
.MenuElement{

}