@keyframes animateIt{
    0%{
scale: 1;
    }
    50%{
scale: 0;
    }
    100%{
scale: 1;
    }
}
.allTests{
    display: flex;
    flex-direction: column;
    /* color:red;s */
    /* background-color: #fceeeb; */
    align-items: center;
    gap: 5px;
    /* height: 85vh; */
}
.testQuestionWrapper{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.testQuestion{
    /* margin: 0 20px; */
    /* width: 80%; */
    margin: 0px 10px 20px 20px;
    font-size: 20px;
}
.oneTestDiv{
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: 10px;
    padding: 10px;
    background-color: white;
    /* border: 1px solid gray; */
    border: 1px solid rgba(255, 255, 255, 0.1);
    bottom: 10px;
    box-shadow: 0 3px 7px -1px rgb(0 0 0 / 10%);
    border-radius: 10px;
}
.oneTestDiv div h3{
    font-size: 32px;
    font-weight: 300;
    /* font-family: Lobster, cursive; */
    color: #213341;
    padding: 0 20px 20px 20px;
}
.oneTestDiv div p{
    padding: 20px 10px;
    text-align: left;
}
.hashes{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
    justify-content: center;
}
.hash{
    display: flex;
    background-color: lightblue;
    /* border: 1px solid gray; */
    padding: 2px;
    border-radius: 3px;
    box-shadow: 0 3px 7px -1px rgb(0 0 0 / 10%);
    border-radius: 3px;
}
/* ThomasKilman */
.testsDivTest{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 85vh;
    justify-content: center;
    background-color: #fceeeb;
    /* background-color: aqua; */
}
.testsDivTest>h2{
    width: 90%;
    font-size: 24px;
 font-family: 'Commissioner', sans-serif;
}
.testsDivTest>p{
    width: 50%;
    font-size: 16px;
    /* text-align: left; */
    /* text-indent: 16px; */
    margin: 20px 0;
    box-shadow: 0 3px 7px -1px rgb(0 0 0 / 10%);
    background: white;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    font-style: italic;
    font-weight: 200;
}
.testsDivResult{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 100%; */
    justify-content: center;
    background-color: #fceeeb;
    /* background-color: aqua; */
}
.testWindow{
    display: flex;
    flex-direction: column;
    /* background-color: white; */
    width: 50%;
    height: 60%;
    margin: 20px;
    /* opacity: 1; */
    transition: 2000ms ease-in;
}

/* Анимация для testWindow */
.animateIt{
   animation: animateIt .5s forwards;
}
.testWindowKilman{
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 80%;
    height: 60%;
    margin: 20px;
    /* border: 1px solid rgba(255, 255, 255, 0.1); */
    border-radius: 3px;
    bottom: 10px;
    box-shadow: 0 3px 7px -1px rgb(0 0 0 / 10%);
}
.test{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    /* border: 1px solid rgba(255, 255, 255, 0.1); */
    border-radius: 10px;
    bottom: 10px;
    background-color: white;
    box-shadow: 0 3px 7px -1px rgb(0 0 0 / 10%);
}
.count{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 30%;
    font-size: 48px;
    color: #223341
}
.btnDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 30%;
    
}
.answerBtn{
    display: flex;
    width: 50%;
    height: 80%;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 300;
    /* border: none; */
    background-color: #f1eae9;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    bottom: 10px;
    color: #223341;
    box-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
    transition: ease-in-out 100ms;
    cursor: pointer;
}
.answerBtn:active{
    scale: 1.01;
    box-shadow: 2px 2px 2px rgba(142, 212, 151, 0.544);
    background-color: #ccffc257;
}
.answerBtn:hover{
    scale: 1.05;
    transition: ease-in-out 100ms;
    box-shadow: 0px 0px 10px rgba(153, 153, 153, 0.5);
}
.testHidden{
    display: none;
}
.result{
display: flex;
flex-direction: column;
height: 100%;
width: 100%;
box-sizing: border-box;
    padding: 20px;
    gap: 10px;
}
.resultHidden{
    display: none;
}

/* Бойко */
.testWindowBoyko{
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 80%;
    height: 60%;
    margin: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    bottom: 10px;
    box-shadow: 0 3px 7px -1px rgb(0 0 0 / 10%);
}
.result h5{
    font-size: 24px;
    margin: 0;
}

.testBtn{
    align-items: center;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: #f5e7e4;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    bottom: 10px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: inline-flex;
    gap: 5px;
    left: 10px;
    padding: 10px 20px;
    text-decoration: none;
    transition: background-color 400ms, border-color 400ms;
    font-size: 16px;
    font-family: 'Lobster', cursive;
    color: #213341;
    transition: ease-in-out 300ms;
}
.testBtn:hover{
    scale:1.05;
    transition: ease-in-out 300ms;
    box-shadow: 0px 0px 10px rgba(153, 153, 153, 0.5);
}
.introTotal>h4{
    font-size: 20px;
    margin: 20px 0;
}
.introTotal>p{
    text-align: left;
    text-indent: 20px;
}
.showForMobile{
    display: none;
}

.testDescription>p{
    text-indent: 16px;
}

@media (min-width:768px) and (max-width:950px){
    .testWindow{
        width: 80%;  
}
.testsDivTest>p{
    width: 80%;
        }
}
@media (max-width:768px) {
    .hideForMobile{
        display: none;
    }
    .showForMobile{
        display: block;
    }
    .testQuestion{
        font-size: 16px;
    }
    .testsDivTest>p{
width: 90%;
    }
    .hashes{
        flex-wrap: wrap
    }
    .oneTestDiv{
        width: 90%;
    }
    .oneTestDiv div h3{
            font-size: 20px;
    }
    .testWindow{
            width: 90%;  
    }
    .testWindowBoyko{
        width: 90%;
    height: 60%;
    margin: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    }
    .test{
        justify-content: flex-start;
        gap: 10px;
        /* height: fit-content; */
        /* height: 80%; */
        padding: 20px 0px;
    }
    .count{
        font-size: 24px;   
         height: 20%;

    }
    .answerBtn{

    padding: 5px;
    box-sizing: content-box;
    height: 100%

    }
}