.contactFormWraper {
  display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15vh;
}

.contactFormContent {
  width: 90%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 5vh;
}
.leftSide {
  display: flex;
  height: 100%;
  padding-top: 30px;
  margin-right: 50px;
  flex-direction: column;
  justify-content: flex-start;
}

.separator {
  max-height: 170px;
}
.headerAndSeparator {
  color: #213341;
  align-self: start;
  margin-left: 5%;
}
.leftSideH2 {
  display: flex;
  width: 100%;
  color: #213341;
  text-align: left;
  justify-content: center;
  margin-bottom: 5vh;
}
.rightSide {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.cardForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 300px;
  z-index: 10;
  /* background-color: rgb(255, 255, 255); */
  border-radius: 10px;
  padding: 30px 0px 30px 0px;
  /* border: solid 1px black; */
  /* border: 0.5px solid rgb(188, 188, 188); */
  box-shadow: 0 3px 7px -1px rgb(0 0 0 / 10%);
  background: white;
}

.PinFeedBack{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  gap: 16px;
  justify-content: space-evenly;
}
.PinFeedBack>p{
  text-align: left;
  font-size: 16px;
  text-indent: 16px;
    color: #223341;
}
.FormForm {
  width: 300px;
}

.inputText {
  outline: none;
  font-size: 18px;
  width: 290px;
  height: 80px;
  border-radius: 10px;
  font-family: 'Commissioner', sans-serif;
  border: 1px solid #d0b5afbd;
  margin-bottom: 30px;
  resize: none;
  padding-left: 10px;
  padding-top: 10px;
}

.inputText::placeholder {
  color:#223341;

}

.sendBtn {
  align-items: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: #f5e7e4;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  bottom: 10px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: inline-flex;
  gap: 5px;
  font-weight: 200;
  left: 10px;
  padding: 10px 20px;
  text-decoration: none;
  transition: background-color 400ms, border-color 400ms;
  font-size: 16px;
  font-family: 'Lobster', cursive;
  color: #213341;
}

.userBox {
  position: relative;
}
/* .userBox .inputName,
.userBox .inputPhone {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #000000;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #000000;
  border-image: linear-gradient(
      90deg,
      #d0b5af05 0%,
      #d0b5afbd 25%,
      #d0b5af 50%,
      #d0b5afbd 75%,
      #d0b5af05 100%
    )
    1;
  outline: none;
  background: transparent;
}

.userBox .nameLabel,
.userBox .phoneLabel {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 18px;
  pointer-events: none;
  transition: 0.5s;
}

.userBox .inputName:focus ~ .nameLabel,
.userBox .inputPhone:focus ~ .phoneLabel {
  position: absolute;
  top: -20px;
  left: 0;
  color: #000000;
  font-size: 12px;
}

.userBox .nameValue {
  position: absolute;
  top: -20px;
  left: 0;
  color: #000000;
  font-size: 12px;
  padding: 10px 0;
}

.userBox .phoneValue {
  position: absolute;
  top: -20px;
  left: 0;
  color: #000000;
  font-size: 12px;
  padding: 10px 0;
} */
/* Дополнительно */
.userBox {
  position: relative;
}
.userBox input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #000000;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #000000;
  outline: none;
  background: transparent;
}

.userBox label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #000000;
  pointer-events: none;
  transition: 0.5s;
}
.userBox > input::placeholder {
  opacity: 0;
  transition: ease-in-out 400ms;
}
.userBox > input:focus::placeholder {
  opacity: 1;
  transition: ease-in-out 400ms;
  color: #868181;
}

.userBox input:focus ~ label,
.userBox input:valid ~ label {
  top: -20px;
  left: 0;
  color: #000000;
  font-size: 12px;
}

/* Все остально */
.compDiv{
  display: flex;
    flex-direction: column;
    align-items: center;
}
.compImg{
  width: 50%;
}
@media (max-width: 768px) {
  .cardForm{
width: auto;
  }
  .contactFormContent{
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: center;
  }
  .userBox .nameLabel{
    left: 10%;
  }
  .userBox .phoneLabel{
    left: 10%;
  }
  .inputText{
    width: 90%;
  }

  .userBox .inputName:focus ~ .nameLabel,
.userBox .inputPhone:focus ~ .phoneLabel {
  position: absolute;
  top: -20px;
  left: 8%;
  color: #000000;
  font-size: 12px;
}

.userBox .nameValue {
  position: absolute;
  top: -20px;
  left: 10%;
  color: #000000;
  font-size: 12px;
  padding: 10px 0;
}

.userBox .phoneValue {
  position: absolute;
  top: -20px;
  left: 10%;
  color: #000000;
  font-size: 12px;
  padding: 10px 0;
}
.rightSide{
  width: 100%;
}
.cardForm{
  width: 100%;
}
.contactFormWraper{
  margin-top: 10vh;
}
.leftSide{
  margin-right: 0;
  padding-top: 0;
}
.headerAndSeparator{
  align-self: center;
    margin-left: 0;
    width: 95%;
}
}