.oneBlock{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    scale: 0.9;
    border-radius: 10px;
    object-fit: cover;
}

.photoBlock{
    object-fit: revert; 
    display: flex;
    height: 400px;
    width: 100%;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    scale: 0.8;
}
.oneBlockImg{
content: initial;
    width: auto;
    scale: 0.9;
}
