@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15vh;
}
.servicesDiv{
    display: flex;
    flex-direction: column;
    width: 90%;
}
.servicesHeader{
display: flex;
width: 100%;
color: #213341;
text-align: center;
justify-content: center;
margin-bottom: 5vh;
}
.servicesList{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 50px;
    justify-content: space-between;
    border-radius: 20px
    ;
}
.service{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25%;
    margin: 10px 0 10px 0;
    border-radius: 10px;
    bottom: 10px;
    box-shadow: 0 3px 7px -1px rgb(0 0 0 / 10%);   
    gap: 5px;
    left: 10px;
    padding: 10px 20px;
    text-decoration: none;
    background-color: #ffffff;
    z-index: 10;
}



.serviceIcon{
    display: flex;
    justify-content: center;
    z-index: 9;
    width: 100%;
}
.serviceIcon img{
width: 50%;
}
.serviceName{
    display: flex;
    justify-content: center;
    color:#213341
}
.serviceName>h3{
    font-size: 16px;
    font-weight: 200;
    font-family: 'Lobster', cursive;
}
.serviceDetails{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
    height: 40%;
    color: #213341;
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;
    text-align: start;
}
.servicePrice{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
.priceIcon{
    width: 10%;
}
.serviceTime{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.timeIcon{
    width: 10%;
}
.serviceButton{
    display: flex;
    justify-content: center
}
.serviceButtonElement {
    align-items: center;
    backdrop-filter: blur(3px);
    background-color: #f5e7e4;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    bottom: 10px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;  
    display: inline-flex;
    gap: 5px;
    left: 10px;
    padding: 10px 20px;
    text-decoration: none;
    transition: background-color 400ms, border-color 400ms;
    font-size: 16px;
    font-family: 'Lobster', cursive;
    color:#213341;
    transition: ease-in-out 300ms;
  }
  .serviceButtonElement:hover{
    scale: 1.05;
    box-shadow: 0px 0px 10px rgba(153, 153, 153, 0.5);
    transition: ease-in-out 300ms;
  }
  .headerAndSeparator{
    margin-bottom: 5vh;
    color:#213341;
    align-self: start;
    margin-left: 5%;
}

  @media (max-width:768px) {
    .service{
        width: 100%;
    }
    .servicesList{
        justify-content: center
    }
    .headerAndSeparator{
        align-self: center;
        margin-left: 0;
        width: 95%;
    }
  }