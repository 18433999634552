.allTests{
    display: flex;
    flex-direction: column;

    align-items: center;
    gap: 5px;
    margin-bottom: 50px;
    margin-top: 50px;

}
.oneTestDiv{
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: 10px;
    padding: 10px;
    background-color: white;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    bottom: 10px;
    box-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
}
.hashes{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
    justify-content: center;
}
.titleTests{
    display: none;
    font-size: 5vh;
    font-weight: 300;
    font-family: Lobster, cursive;
    color: #213341;
    padding: 20px;
}
.hash{
    display: flex;
    background-color: lightblue;
    border: 1px solid gray;
    border-radius: 3px;
}
/* ThomasKilman */
.testsDivTest{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 85vh;
    justify-content: center;
    background-color: #fceeeb;
}
.testsDivResult{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fceeeb;
}
.testWindow{
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 50%;
    height: 60%;
    margin: 20px;
}
.testWindowKilman{
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 80%;
    height: 60%;
    margin: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    bottom: 10px;
    box-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
}
.test{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    bottom: 10px;
    box-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
}
.count{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 30%;
    font-size: 48px;
    color: #223341
}
.btnDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 30%;
    
}
.answerBtn{
    display: flex;
    width: 80%;
    height: 80%;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 300;
    /* border: none; */
    background-color: #f1eae9;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    bottom: 10px;
    box-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
    transition: ease-in-out 100ms;
    cursor: pointer;
}
.answerBtn:hover{
    scale: 1.01;
    box-shadow: 2px 2px 2px rgba(5, 77, 14, 0.544);
    background-color: #e9e4e3;
}
.testHidden{
    display: none;
}
.result{
display: flex;
flex-direction: column;
height: 100%;
width: 100%;
}
.resultHidden{
    display: none;
}

/* Бойко */
.testWindowBoyko{
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 80%;
    height: 60%;
    margin: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    bottom: 10px;
    box-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
}
.result h5{
    font-size: 24px;
    margin: 0;
}
@media (max-width:768px) {
    .hashes{
        flex-wrap: wrap
    }
    .oneTestDiv{
        width: 90%;
    }
 
}