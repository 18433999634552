.blogCard {
  width: 75%;
  margin: 10px;
  padding: 10px;
  background-color: white;
  border: 1px solid rgba(255, 255, 255, 0.1);
  bottom: 10px;
  box-shadow: 0 3px 7px -1px rgb(0 0 0 / 10%);
  border-radius: 10px;
}

.title {
  font-size: 32px;
  font-weight: 300;
  color: #213341;
  padding: 0 0 20px 0;
}

.theme {
  padding: 10px 20px;
  text-align: left;
}

.blogBtn {
    background-color: #f5e7e4;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    bottom: 10px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: inline-flex;
    gap: 5px;
    left: 10px;
    padding: 10px 20px;
    /* position: fixed; */
    text-decoration: none;
    transition: background-color 400ms, border-color 400ms;
    font-size: 16px;
    font-family: 'Lobster', cursive;
    color: #213341;
    transition: ease-in-out 300ms;
}
.blogBtn:hover{
  scale:1.05;
  transition: ease-in-out 300ms;
  box-shadow: 0px 0px 10px rgba(153, 153, 153, 0.5);
}
@media (max-width: 768px) {
  .title{
    font-size: 20px;
  }
  .blogCard{
    width: 90%;
  }
}