.wrapper{
    display: flex;
    flex-direction: column;
    margin-top: 15vh;
    align-items: center;
}
.headerAndSeparator{
    margin-bottom: 5vh;
    color:#213341;
    align-self: start;
    margin-left: 5%;
}
.slider {
    position: relative;
    width: 80%;
    height: 500px;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    overflow: hidden;
}
 
.sliderImg {
    position: absolute;
    width: 100%;
    height: 500px;
    transition: all 0.2s;
}
 
.sliderImgPrev {
    transform: translateX(-100%);
}
 
.sliderImgNext {
    transform: translateX(100%);
}

@media (max-width:768px) {
    .wrapper{
        margin-top: 10vh;
    }
        .headerAndSeparator{
            align-self: center;
            margin-left: 0;
            width: 95%;
        }
}