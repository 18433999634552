.apexcharts-toolbar{
    display: none !important;
}
.apx-legend-position-left{
    align-items: baseline;
}
@media (max-width:768px) {
    .apexcharts-xaxis-texts-g{
display: none;
    } 
}
