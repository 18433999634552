.wrapper{
  position: relative;
  background-color: aliceblue;
  z-index: 10;

}
.footer {
  /* background-color: #f5e7e4; */
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
    padding: 10px 5% 10px 5%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  background-image: linear-gradient(0deg, rgba(245,231,228,0.5) 0%, rgba(245,231,228,0.5) 100%), url('./svgbg222.svg');
  background-position: top 300px right 0px;
  background-size: cover;
}
.left_block {
  display: flex;
  width: 30%;
  /* border-top: solid 1px black;
  width: 300px;
  margin-top: 50px;
  margin-right: 30px;
  padding-top: 50px; */
}
.right_block {
  display: flex;
    /* border-top: solid 1px black; */
    /* width: 300px; */
    /* margin-top: 50px; */
    /* margin-left: 30px; */
    /* padding-top: 50px; */
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
    /* height: 50%; */
    width: 100%;
    /* background: white; */
    padding: 10px;
    border-radius: 10px;
    /* box-shadow: 0 3px 7px -1px rgb(0 0 0 / 10%); */
    /* background: #44444436; */
    /* -webkit-backdrop-filter: blur(10px); */

}
.right_block_menus{
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
}
  /* backdrop-filter: blur(6px);
    
}

.center_block {
  display: flex;
  flex-direction: column;
}
.footer_logo {
}

.logo_div {
  width: 80%;
  filter: drop-shadow(0.5px 0.5px 1px rgba(48, 48, 65, 0.81));
}


/* .center_line {
  width: 1px;
  height: 40px;
  background-color: black;
  margin-left: 50%;
  margin-top: 10px;
} */

.social_div {
  display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    width: 50%;
    gap: 10px;
 }

.copyRight {
  margin-top: 10px;
}

.footer_link {
  text-decoration: none;
  color: #223341;
  width: 50%;
  /* background-color: #f5e7e4ba; */
  padding: 5px;
  /* border-radius: 3px; */
  /* box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1); */
  text-align: left;
  font-family: 'Lobster', cursive;
  color: #223341;
}
.icon{
  width: 30px;
}

@media (max-width: 768px) {
  .right_block{
    display: flex;
    flex-direction: column;
        width: 100%; 
  }
  .left_block{
    display: none;
  }
  .social_div{
    width: 100%;
    justify-content: center;
  }
}