@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.AboutMeDiv{
    box-sizing: content-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-top: 5vh;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 50px;
    border-radius: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
   box-shadow: 0 3px 7px -1px rgb(0 0 0 / 10%);
    background: white;
    z-index: 10;
}
.MyDivLR{
    display: flex;
    flex-direction: row;
    width: 100%;
    /* border-bottom: 1px solid black;
    border-image:linear-gradient(90deg, #d0b5af05 0%, #d0b5afbd 25%, #d0b5af 50%, #d0b5afbd 75%, #d0b5af05 100%) 1; */

}
.AboutMeLeftDiv{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 20px;
    gap: 10px;
    width: 50%;
    border-radius: 10px;
    background: white;
}
.AboutMeLeftP{
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 16px;
    gap: 16px;
    color: #223341;
}
.AboutMeLeftP>p{
    margin: 0;
    padding: 0;
    font-size: 16px;
    /* text-indent: 16px; */
}
.AboutMeRightDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 50%;

}
.myPhoto{
    position: absolute;
    top: -75px;
    object-fit: cover;
    display: flex;
    height: 150px;
    width: 150px;
    vertical-align: middle;
    background-size: cover;
    background-repeat: no-repeat;
    /* background-image: url('./img//IMG_1580.jpg'); */
    border-radius: 100px;
    border: 0.5px solid #fbcbd6;
    box-shadow: 0 0 20px 0.5px #7674748f;
    align-items: center;
    justify-content: center;
}

.AboutMeDivInBottomDiv{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 70%;
    margin: 10px;
    justify-content: center;
    color: white;
}
.myPhotoPNG{
    -webkit-filter: drop-shadow(0px 0px 42px rgba(48, 48, 65, 0.41));
  filter: drop-shadow(0px 0px 42px rgba(48, 48, 65, 0.41));
  /* border-image:linear-gradient(90deg, #d0b5af05 0%, #d0b5afbd 25%, #d0b5af 50%, #d0b5afbd 75%, #d0b5af05 100%) 1; */
  border-radius: 10px;
}
.AboutMeLeftH3{
font-size: 36px;

}
.AboutMeLeftH3 h3{
    margin-bottom: 0;
    margin-top: 0;
}
.AboutMeLeftH3 text{
    margin-bottom: 0;
    margin-top: 0;
}
.AboutMeLeftH4{
    
}
.AboutMeLeftH4>h2{
    font-size: 28px;
    color: #049cb3;
    font-family: Lobster, cursive;
}
.AboutMeLeftH4 h2{
    margin-top: 0;
}

@media (max-width:768px) {

    .AboutMeRightDiv{
        width: 80%;
        margin-top: 20px;
    }
    .MyDivLR{
        flex-direction: column;
        align-items: center
    }
    
    .AboutMeDivInBottomDiv{
        width: 80%;
    }
    .AboutMeDiv{
        margin-bottom: 0;
    }
    .AboutMeLeftH3{
        display: flex;
        width: min-content;
    }
    .AboutMeLeftH4 h4{
        font-size: 20px;
    }
    .AboutMeLeftDiv{
            margin-left: 0px;
     width: auto; 
    }
    .AboutMeLeftP p{
        margin: 0 20px;
    }
}