@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Philosopher&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* body {  */
  /* margin: 0; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    /* font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
/* } */

html{
  scroll-behavior: smooth;
}



body {
  margin: 0;
  /* font-family: 'Philosopher', sans-serif; */
  font-family: 'Commissioner', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h2{
  font-size: 5vh;
  font-weight: 300;
    font-family: Lobster, cursive;
    color: #213341;
}

h4{
  font-weight: 200;
}


a{
  text-decoration: none;
}