.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15vh;
}

.educationDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 3px 7px -1px rgb(0 0 0 / 10%);
    background: white;
    border-radius: 10px;
    width: 90%;
    margin: 10px;
    box-sizing: border-box;
    padding: 20px;
    z-index: 10;
}
.educationHeader{
    margin-top: 5vh;
    margin-bottom: 5vh;
    color: #223341;
}
.educationLR{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
}
.threeDiploma{
    display: flex;
    width: 50%;
    height: fit-content;
    align-items: flex-start;
}
.swiper-button-prev{
    color:#009cb3
}
.swiper-button-next{
    color:#009cb3;
    top: 40%;
}
/* ff */
.oneOfDiploma{

}
.headerAndSeparator{
    margin-bottom: 5vh;
    color:#213341;
    align-self: start;
}
.educationLeft{
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: left;
    justify-content: center;
}
.educationLeft>p{
    text-align: left;
    font-size: 16px;
    color: #223341;
}
.educationBrief{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    text-align: left;
    gap: 16px;
}
.educationBrief>p{
    text-indent: 16px;
}
.educationLeft ul li{
    list-style-image: url(./svg/educationCap.svg);
    padding: 5px;
    margin-left: 20px;
}
.educationRight{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    gap: 10px;
    justify-content: center;
    box-shadow: 0 3px 50px 5px rgb(0 0 0 / 10%);
    border-radius: 10px;
    text-decoration: none;
}
.oneDiplomDiv{
    display: flex;
    flex-direction: column;
justify-content: center;
gap: 10px;
justify-content: flex-start;
align-items: center
}
.oneDiplom{
    width:80%;
    scale: 0.9;
border-radius: 5px;
}
.diplomaSvgIcon{
    height: 200px;
    margin-top: 10px;
    width: auto;
    transition: 400ms ease-in-out;
}
.diplomaSvgIcon:hover{
    scale: 1.03;
    transition: 400ms ease-in-out;
}
.diplomaSvgIcon:hover::after{
    content: attr(data-title); 
    position: absolute; /* Абсолютное позиционирование */
    left: 20%; top: 30%; /* Положение подсказки */
    z-index: 1; /* Отображаем подсказку поверх других элементов */
    background: rgba(255,255,230,0.9); /* Полупрозрачный цвет фона */
    font-family: Arial, sans-serif; /* Гарнитура шрифта */
    font-size: 11px; /* Размер текста подсказки */
    padding: 5px 10px; /* Поля */
    border: 1px solid #333; /* Параметры рамки */
}
.diplomaIconSpan{
    display: none;
    height: 0px;
    /* visibility: hidden;  */
    transition: 200ms ease-in-out;
}
.onSpanHover{
    display: flex;
    height: 60px;
    height: auto;
    visibility: visible;
    display: flex;
    color:red;
    background-color: #223341;
    transition: margin .5s;
    margin: 10px 0 0 0  ;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out
}
.diplomaSpanImgMini{
    width: 100px;
    height: auto;
    background-size: cover;
    transition: 200ms ease-in-out;
}
.aHrefDiploma{
    text-decoration: none;
   
    display: contents;
}
.hrefDiploma{
    width: 50%;
    font-weight: 300;
    font-style: italic;
    color: grey;
    font-size: 12px;
}

@media (max-width: 768px) {
    .wrapper{
        margin-top: 10vh;
    }
  .educationLR{
    flex-direction: column;
    align-items: center
  }
  .educationLeft{
    width: 100%;
  }
  .threeDiploma{
    width: 100%;
  }
  .headerAndSeparator{
    align-self: center;
    margin-left: 0;
    width: 95%;
  }
  }