.blogPage{
display: flex;
flex-direction: column;
align-items: center;
margin: 50px;
background-color: white;
box-shadow: 0 3px 7px -1px rgb(0 0 0 / 10%);
border-radius: 10px;
}
.blogTitle{
    font-size: 4vh;
    font-weight: 300;
    font-family: 'Commissioner', sans-serif;
    color: #213341;
    margin: 50px 10px;
}
.blogContent{
width: 80%;
text-align: left;
}
.blogContent p{
    margin-top: 10px;
    margin-bottom: 10px;
}
.blogContent ul{
    list-style-image: url(./heartlist.svg);
    margin-top: 10px;
    margin-bottom: 10px;
}
.blogContent ul>li{
    font-size: 14px;
    padding: 3px;
    color: rgb(65, 65, 65);
}
.blogContent ul>li>p{
    font-style: italic;
    
    color: rgb(40, 40, 40);
}
.blogContent ul>li>ul>li{
    margin-left: 20px;
}
.blogSignature{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
    width: 90%;
    height: 50px;
    border-top: 1px solid#213341;
    border-image: linear-gradient(90deg, #21334105 0%, #213341bd 25%, #213341 50%, #213341bd 75%, #21334105 100%) 1;
}

.blogSignatureP{
    color: rgb(65, 65, 65);
}


.blogBtn2{
    align-items: center;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: #f5e7e4;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    bottom: 10px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: inline-flex;
    gap: 5px;
    left: 10px;
    padding: 10px 20px;
    text-decoration: none;
    transition: background-color 400ms, border-color 400ms;
    font-size: 16px;
    font-family: 'Lobster', cursive;
    color: #213341;
    margin-bottom: 50px;
    transition: ease-in-out 300ms;
}
.blogBtn2:hover{
    scale:1.05;
    transition: ease-in-out 300ms;
    box-shadow: 0px 0px 10px rgba(153, 153, 153, 0.5);
}

@media (max-width: 768px) {
    .blogPage{
        margin: 10px;
    }
    .blogTitle{
        font-size:24px;
    }

}