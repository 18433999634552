*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

:root {
  --primary: #6a59ff;
  --white: #ffffff;
  --bg: linear-gradient(to right, #fdf8f2 0%, #f5e7e4 100%);
  --swiper-navigation-size: 24px;
}

html {
  scroll-behavior: smooth;
}

@media (min-width: 1440px) {
  html {
    zoom: 1.5;
  }
}

@media (min-width: 2560px) {
  html {
    zoom: 1.7;
  }
}

@media (min-width: 3860px) {
  html {
    zoom: 2.5; 
  }
}

/* ::-webkit-scrollbar {
  width: 1.3rem;
} */

/* ::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #797979;
  transition: all 0.5s ease-in-out;
} */

/* ::-webkit-scrollbar-thumb:hover {
  background: #222224;
} */

/* ::-webkit-scrollbar-track {
  background: #f9f9f9;
} */

body {
  background: var(--bg);
}

.wrapperSwiper{
    display:flex;
    width: 100%;
}
.wrapperSwiper>h2{
    margin-bottom: 5vh;
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  width: 100%;
}

.heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
}

.swiper_container {
width: 100%;
height: 400px;
  position: relative;
}

.swiper-container{
    height: 400px;

    margin-right: 0;
    position: relative;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: block;
}
.swiper-3d{
    perspective: 1000px;
    z-index: 10;
}
.slider-controler .slider-arrow{
    display: none;
}
.my-slide{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 300px;
    box-shadow: 0 3px 7px -1px rgb(0 0 0 / 10%);
    background: white;
    gap: 5px;
    left: 10px;
    padding: 10px 20px;
    border-radius: 10px;
    z-index: 11;
        text-decoration: none;
}

.myslidePhoto{
    object-fit: cover;
    display: flex;

    height: 150px;
    width: 150px;
    vertical-align: middle;
    background-size: cover;
  
    background-repeat: no-repeat;
    background-image: url('././assets/img_1.jpg');
    border-radius: 100px;
    border: 0.5px solid #fbcbd6;
    box-shadow: 0 0 20px 0.5px #7674748f;
    align-items: center;
    justify-content: center;
}
.swiper-slide {
  width: 300px;
  position: relative;
}
.swiper-slide img {
    width: 100%;
    height: 50%;
    border-radius: 10px;
z-index: 8;
    object-fit: cover;}

/* @media (max-width: 500px) {
  .swiper_container {
    height: 47rem;
  }
  .swiper-slide {
    width: 28rem !important;
    height: 36rem !important;
  }
  .swiper-slide img {
    width: 28rem !important;
    height: 36rem !important;
  }
} */

.swiper-slide img {

  width: 100%;
  height: 80%;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

@media (max-width:768px) {
  .my-slide{
    left: -55px;
    width: 270px;
  }
  .swiper-slide img{
    object-fit: contain;
    border-radius: 10px;
  }
  
}

@media (max-width:360px) {
  .my-slide{
    width: 220px;
    left: -30%;
  }
  
}

.slider-controler .slider-arrow {
  background: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: '';
}

.swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}