.problemsDiv{
    display: flex;
    flex-direction: row;
    /* background-color: #f5e7e4; */
    justify-content: space-evenly;
    align-items: center;
    margin: 20px;

    padding: 10px;
    border-radius: 10px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    }
    .problemsHeader{
    color:#213341
    }
    .problemsHeader>h2{
        /* text-align: left;
        font-weight: 300; */
    }
    .problemsList{
        display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    /* gap: 5px; */
    align-items: center;
    justify-content: center;
    }
    .problemsLR{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        /* gap: 5px; */
        align-items: center;
        justify-content: center;
    }
    /* .problemsList::-webkit-scrollbar {
        width: 5px;
        width: 10em;
        background-color: #c05e5e;
    }
    .problemsList::-webkit-scrollbar-thumb {
        border-radius: 3px;
        width: 10em;
        background-color: #afa7b2;
    }
    .problemsList::-webkit-scrollbar-track {
        width: 10em;
        border-radius: 3px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #a29696;
    }
    .problemsList::-webkit-scrollbar:vertical{
        display: none;
    } */
    
    .problem{
        display: flex;
        flex-direction: column;
        padding: 0 5px 0 5px;
        backdrop-filter: blur(3px);
        /* background-color: rgba(255, 255, 255, 0.05); */
        background-color: #f5e7e4;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 3px;
        bottom: 10px;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
        /* cursor: pointer;   */
        /* gap: 5px; */
        margin: 5px;
        /* left: 10px; */
        /* padding: 10px 20px; */
        /* position: fixed; */
        /* margin-bottom: 20px; */
        /* border-radius: 20px; */
        text-decoration: none;
        transition: background-color 400ms, border-color 400ms;
    }
    .problemImgDiv{
    display: flex;
    justify-content: center;
    /* position: relative; */
    overflow:hidden;
    /* width:300px;
    height:300px; */
    }
    .problemImg{
        /* position: absolute;
        top:50%;
        left:50%; */
        transform:translate(-50%,-50%);
        /* width:300px; */
        /* height:300px; */
        object-fit:cover; height: 100%;
    border-radius: 5px;
    }
    .problemH3{
        font-size: 12px;
    color:#213341
    }
    .problemH3>h3{
        margin-block-start: 5px;
        margin-block-end: 5px;
        font-weight: 300;
    }
    .problemsHeaderH2{
        font-size: 24px;
        color:#213341;
        font-family: 'Commissioner', sans-serif;
        text-align: left;
    font-weight: 500;
    }

    @media (max-width:768px) {
        .problemsDiv{
            flex-direction: column;
        }
        .problemH3>h3{
            width: max-content;
        }
        .problemsHeaderH2{
            text-align: center;
            margin-bottom: 10px;
        }
        
    }

    @media (max-width:370px){
        .problemsHeaderH2{
            text-align: center;
        }
        
    }

    @media (max-width:350px) {
        .problemH3{
            font-size: 10px;
        }
        
    }