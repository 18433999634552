@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;}

.BenefitsH2{
    display: flex;
    color: #223341;
    width: 100%;
    font-size: 36px;
    text-align: left;
    flex-direction: row;
    justify-content: flex-start;
}
.BenefitsH2>h2::first-letter{
    vertical-align: -10px;
    font-size: 60px;
    color: rgb(255, 115, 0);
}
.BenefitsDiv{
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;
}
.BenefitsDivUpperRow{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    
}
.BenefitsDivLowerRow{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.BenefitElementDiv{
    display: flex;
    width: 30%;
    align-items: center;
    justify-content: flex-start;
    align-content: flex-start;
    flex-direction: column;
    z-index: 10;
}
.BenefitImg{

}
.BenefitH4{
    display: flex;
    flex-direction: column;
    font-family: 'Lobster', cursive;
    color: #223341;
    border-bottom: 1px solid black;
    padding-bottom: 5px;
    border-image: linear-gradient(90deg, #d0b5af05 0%, #d0b5afbd 25%, #d0b5af 50%, #d0b5afbd 75%, #d0b5af05 100%) 1;
    -webkit-border-image: linear-gradient(0deg, #d0b5af05 0%, #d0b5afbd 5%, #d0b5af 0%, #d0b5afbd 0%, #d0b5af05 0%) 2  
    
}

.BenefitH4>h4{
    font-size: 24px;
}
.BenefitP{
    font-size: 16px;
    text-align: left;
}

@media (max-width: 768px) {
    .BenefitsDivUpperRow{
        display: flex;
        flex-direction: column;
        gap:40px;
        align-items: center;
        justify-content: space-around;
    }
        .BenefitElementDiv{
            width: auto;
        }
  .BenefitP{
    text-align: center;
  }
  }