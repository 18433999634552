/* Transition */

@keyframes show-modal {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.1;
  }
  20% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.6;
  }
  70% {
    opacity: 0.7;
  }
  80% {
    opacity: 0.8;
  }
  90% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}
/* .myModalContent.entering{
    animation: ease-in .5s forwards;
} */
/* .myModalContent.exiting{
    animation: ease-in .5s reverse;
} */

/*Anton*/
/*ANTON2*/

.close_btn {
  position: absolute;
  border: none;
  outline: none;
  padding: 0px;
  background: none;
  top: 10px;
  right: 10px;
}
.userBox {
  position: relative;
}
.userBox input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #000000;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #000000;
  outline: none;
  background: transparent;
}

.userBox label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #000000;
  pointer-events: none;
  transition: 0.5s;
}
.userBox > input::placeholder {
  opacity: 0;
  transition: ease-in-out 400ms;
}
.userBox > input:focus::placeholder {
  opacity: 1;
  transition: ease-in-out 400ms;
  color: #868181;
}

.userBox input:focus ~ label,
.userBox input:valid ~ label {
  top: -20px;
  left: 0;
  color: #000000;
  font-size: 12px;
}

.policy {
  display: flex;
  align-items: center;
}
.policy_label {
  font-size: 12px;
  color: #868181;
  padding-bottom: 2px;
}
/*Anton*/

.myModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: none;
  background: rgba(4, 4, 4, 0.2);
  backdrop-filter: blur(3px);
  opacity: 0;
  animation: show-modal 0.1s forwards;
  pointer-events: none;
  transition: 0.5s;
  z-index: 20;
}
/* .myModal.active{
   
/* transform: scale(1); */

.myModal.active {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  pointer-events: all;
  transition: 0.5s;
  /* animation: show-modal .1s reverse; */
}

.myModalContent {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 400px;
  height: 55vh;
  background: rgba(255, 255, 255, 0.91);
  border-radius: 5px;
  min-width: 250px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 1px rgb(0 0 0 / 79%);
  z-index: 20;
}
.myModalContent h2 {
  margin-bottom: 0;
}
.myModalContent h3 {
  font-size: 12px;
}
.myModalContent h4 {
  margin-top: 0;
}

.threeP {
  margin-left: 100px;
}
.loginForm {
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 80%;
  /* height: 50%; */
}
.formName {
  text-align: center;
}
.formLabel {
  font-size: 12px;
  color: rgb(91, 91, 91);
  text-align: center;
}
.loginFormButton {
  /* display: flex;
  border: none;
  color: black;
  background-color: #fff;
  padding: 5px;
  border-radius: 3px;
  transition: ease-in-out 200ms;
  margin: 10px;
  border: 1px solid black; */

  align-items: center;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    /* background-color: rgba(219, 215, 215, 0.6); */
    /* background-color: rgb(255 255 255); */
    background-color: #f5e7e4;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    bottom: 10px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: inline-flex;
    gap: 5px;
    left: 10px;
    padding: 10px 20px;
    /* position: fixed; */
    text-decoration: none;
    transition: background-color 400ms, border-color 400ms;
    font-size: 16px;
    font-family: 'Lobster', cursive;
    color: #213341 /* z-index: 10000; */;
    transition: ease-in-out 300ms;
}
.loginFormButton:hover {
  scale: 1.05;
  box-shadow: 0px 0px 10px rgba(153, 153, 153, 0.5);
  transition: ease-in-out 300ms;
}
.close_img{
  scale: 0.5;
  cursor: pointer;
  color: #213341;
  transition: ease-in-out 200ms;
  
}
.close_img:hover{
  scale: 0.6;
  color: #000000;
  transform: rotate(90deg);
  animation-duration: 2000ms;

}
@media (max-width: 768px) {
  .myModal {
    position: fixed;
    overflow: auto;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .myModal.active {
 
  }
  .myModalContent {

    width: 90vw;
  }
}
