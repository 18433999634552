.blog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 50px;
}

.titleBlog {
  display: none;
  font-size: 5vh;
    font-weight: 300;
    font-family: Lobster, cursive;
    color: #213341;
  padding: 20px;
}


